.main-date-picker>.ant-picker-input {
    width: fit-content!important;
}

.main-date-picker>.ant-picker-input>input {
    color: #212121!important;
    font-size: 24px!important;
    font-weight: 700!important;
    font-family: DIN Condensed!important;
    width: 83px!important;
    height: 28px!important;
    padding-top: 9px!important;
    border-radius: 0!important;
}

.main-date-picker>.ant-picker-separator {
    color: #212121!important;
}

.main-date-picker>.ant-picker-suffix {
    color: #e47039!important;
    margin-left: 15px!important;
}

.main-date-picker>.ant-picker-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px!important;
    height: 24px!important;
}

.ant-picker-dropdown {
    z-index: 40!important;
}
