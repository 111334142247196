@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.cdnfonts.com/css/din-regular');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


@font-face {
  font-family: "DIN Condensed";
  src: local("DIN Condensed"), url("./assets/fonts/din-condensed/DINCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "DIN Regular";
  src: local("DIN Regular"), url('https://fonts.cdnfonts.com/css/din-regular') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "DIN Alternate";
  src: url('assets/fonts/din-condensed/DIN Alternate Bold.otf') format("opentype");
  font-weight: normal;
}

body {
  font-family: 'DIN-Regular', sans-serif;
  max-width: 1600px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.condensed {
  font-family: 'DIN Condensed', 'Segoe UI', 'Roboto';
  padding-bottom: 0;
}

.condensed-regular {
  font-family: 'DIN Regular', 'Segoe UI', 'Roboto';
  padding-bottom: 0;
}

.condensed-alternate {
  font-family: 'DIN Alternate', 'Segoe UI', 'Roboto';
  padding-bottom: 0;
}

.roboto {
  font-family: 'Roboto', 'Segoe UI', 'Roboto';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-gradient {
  background: linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: block;
  width: fit-content;
}

.text-gradient-blue {
  background: linear-gradient(135deg, #00ABE9 0%, #00ABAB 40.62%, #CED955 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: block;
  width: fit-content;
}

.background-gradient {
  background: linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%);
}

.background-gradient-blue {
  background: linear-gradient(135deg, #00ABE9 0%, #00ABAB 40.62%, #CED955 100%);
}

.card-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card-bottom-shadow {
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.15);
}


/* Rendu du rich texte */
.html-rendered> :is(blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre, p, a, ol, ul, br) {
  all: revert;
}

.html-rendered a,
.html-rendered>p>a {
  color: #E36D38;
  text-decoration: revert;
}

.table-shadow-outter {
  box-shadow: 0 1.91473px 7.65894px 0 rgba(0, 0, 0, 0.12);
}

.table-shadow-left {
  box-shadow: 10px 0 7px -5px rgba(24, 39, 75, 0.12);
}

.table-shadow-right {
  box-shadow: -10px 0 7px -5px rgba(24, 39, 75, 0.12);
}