  .checkbox-component {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position:relative;
    width: 20px;
    height: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
  }

  .checkbox-orange:checked {
    background-image: url("../../../../assets/checkbox-input.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    border: none;
    outline: none;
  }

  .checkbox-blue:checked {
      background-image: url("../../../../assets/icons/blueStyle/checkbox-input-blue.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      border: none;
      outline: none;
  }
