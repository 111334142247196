.ql-container {
    height: calc(100% - 48px) !important;
    font-family: 'DIN-Regular', sans-serif !important;
    font-size: 16px !important;
}

.ql-editor.ql-blank::before {
    font-family: 'DIN-Regular', sans-serif !important;
    font-size: 16px !important;
    color: #00000040 !important;
    font-style: normal !important;
}

.ql-snow .ql-tooltip {
    left: 10px !important;
}
