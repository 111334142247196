.table-select-input-container {
    width: 80% !important;
    margin: 0 auto !important;
    font-family: 'DIN Regular', 'Segoe UI', 'Roboto' !important;
    font-weight: 400 !important;
}

.table-select-input__indicators {
    height: 24px !important;
    padding-right: 8px !important;
}

.table-select-input__control {
    margin: 0 !important;
    width: 100% !important;
    border-radius: 4px !important;
    border: none !important;
}

.table-select-input__input-container {
    height: fit-content !important;
    padding: 0 !important;
    margin: 0 !important;
}

.table-select-input__value-container {
    padding: 0 8px !important;
}

.table-select-input__menu {
    margin-top: 0 !important;
    width: fit-content !important;
    min-width: 100% !important;
}


.table-select-input__menu-list {
    padding: 0 !important;
}

.table-select-input__single-value {
    color: #212121 !important;
}

.table-select-input__placeholder {
    color: #21212140 !important;
}