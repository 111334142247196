.select-input__indicators {
    height: 31px!important;
}

.select-input__control {
    margin: 0!important;
}

.select-input__input-container {
    height: fit-content!important;
    padding: 0!important;
    margin: 0!important;
}

.select-input__value-container {
   padding: 0 8px!important;
   margin-bottom: 4px;
}

.select-input__menu {
    margin-top: 0!important;
}

.select-input__menu-list {
    padding: 0!important;
}

.select-input__single-value {
    color: #212121!important;
}

.select-input__placeholder {
    color: #21212140!important;
}
