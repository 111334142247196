 /* ANT design steps Orange Color */
 .roadstep-orange {
    font-family: 'DIN Regular', 'Segoe UI', 'Roboto';
    font-weight: 5;
  }

  /* Step Item */
  .roadstep-orange > .ant-steps-item > .ant-steps-item-container >.ant-steps-item-icon {
    background: linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roadstep-orange > .ant-steps-item > .ant-steps-item-container >.ant-steps-item-icon > span {
    color: white;
  }

  .roadstep-orange > .ant-steps-item > .ant-steps-item-container >.ant-steps-item-content > .ant-steps-item-title {
    background: linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Step Active */
  .roadstep-orange > .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roadstep-orange > .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    background: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  /* Step Wait */
  .roadstep-orange > .ant-steps-item-wait > .ant-steps-item-container >.ant-steps-item-icon {
    background: white;
    border: 1px solid rgba(33, 33, 33, 0.45);
    color: rgba(33, 33, 33, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roadstep-orange > .ant-steps-item-wait > .ant-steps-item-container >.ant-steps-item-icon > span {
    color: rgba(33, 33, 33, 0.45);
  }

  .roadstep-orange > .ant-steps-item-wait > .ant-steps-item-container >.ant-steps-item-content > .ant-steps-item-title {
    background: rgba(33, 33, 33, 0.45);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  /* Step Finish */
  .roadstep-orange > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    background: linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .roadstep-orange > .ant-steps-item-finish > .ant-steps-item-container >.ant-steps-item-icon {
    background: transparent;
    border: 1px solid #DF5335;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roadstep-orange > .ant-steps-item-finish > .ant-steps-item-container >.ant-steps-item-icon > .ant-steps-icon > .ant-steps-finish-icon {
    color: #DF5335;
  }

  .roadstep-orange > .ant-steps-item-finish > .ant-steps-item-container >.ant-steps-item-tail::after {
    background: linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%);
  }


   /* ANT design steps Blue Color */
 .roadstep-blue {
    font-family: 'DIN Regular', 'Segoe UI', 'Roboto';
    font-weight: 5;
  }

  /* Step Item */
  .roadstep-blue > .ant-steps-item > .ant-steps-item-container >.ant-steps-item-icon {
    background: linear-gradient(135deg, #00ABE9 0%, #00ABAB 40.62%, #CED955 100%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roadstep-blue > .ant-steps-item > .ant-steps-item-container >.ant-steps-item-icon > span {
    color: white;
  }

  .roadstep-blue > .ant-steps-item > .ant-steps-item-container >.ant-steps-item-content > .ant-steps-item-title {
    background: linear-gradient(135deg, #00ABE9 0%, #00ABAB 40.62%, #CED955 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  /* Step Active */
  .roadstep-blue > .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roadstep-blue > .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    background: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  /* Step Wait */
  .roadstep-blue > .ant-steps-item-wait > .ant-steps-item-container >.ant-steps-item-icon {
    background: white;
    border: 1px solid rgba(33, 33, 33, 0.45);
    color: rgba(33, 33, 33, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roadstep-blue > .ant-steps-item-wait > .ant-steps-item-container >.ant-steps-item-icon > span {
    color: rgba(33, 33, 33, 0.45);
  }

  .roadstep-blue > .ant-steps-item-wait > .ant-steps-item-container >.ant-steps-item-content > .ant-steps-item-title {
    background: rgba(33, 33, 33, 0.45);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  /* Step Finish */
  .roadstep-blue > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    background: linear-gradient(135deg, #00ABE9 0%, #00ABAB 40.62%, #CED955 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .roadstep-blue > .ant-steps-item-finish > .ant-steps-item-container >.ant-steps-item-icon {
    background: transparent;
    border: 1px solid #00ABAB;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roadstep-blue > .ant-steps-item-finish > .ant-steps-item-container >.ant-steps-item-icon > .ant-steps-icon > .ant-steps-finish-icon {
    color: #00ABAB;
  }

  .roadstep-blue > .ant-steps-item-finish > .ant-steps-item-container >.ant-steps-item-tail::after {
    background: linear-gradient(135deg, #00ABE9 0%, #00ABAB 40.62%, #CED955 100%);
  }
