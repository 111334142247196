/* orange style */
.dashboard-orange>.ant-progress-outer>.ant-progress-inner>.ant-progress-bg {
    height: 15px!important;
    background: linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.dashboard-orange {
    display: flex;
    justify-content: space-between;
    margin: 0!important;
    padding: 0!important;
}

.dashboard-orange>.ant-progress-text {
    font-size: 24px!important;
    color: black!important;
    font-family: 'Din Condensed', sans-serif!important;
    width: fit-content!important;
}

/* white style */
.dashboard-white>.ant-progress-outer>.ant-progress-inner>.ant-progress-bg {
    height: 15px!important;
}

.dashboard-white>.ant-progress-outer {
    width: 95%!important;
}

.dashboard-white {
    display: flex;
    justify-content: space-between;
    margin: 0!important;
    padding: 0!important;
}


.dashboard-white>.ant-progress-text {
    font-size: 24px!important;
    color: white!important;
    font-family: 'Din Condensed', sans-serif!important;
    width: fit-content!important;
}