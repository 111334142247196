.range-picker-popup {
    width: fit-content;
    bottom: -40px;
    right: 4px;
    z-index: 99999999 !important;
}

.range-picker-popup .ant-picker-input>input {
    width: 110px !important;
}

.range-picker-popup>.ant-picker-range>.ant-picker-active-bar {
    left: 140px !important;
    width: 85px !important;
}

.range-picker-popup>.ant-picker-range {
    border: none !important;
    border-radius: 4px !important;
}