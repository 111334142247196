.ant-picker-input>input {
    color: #212121!important;
    font-size: 16px!important;
    font-weight: 400!important;
    outline: none;
    width: 209px!important;
    height: 21px!important;
    border-radius: 0 !important;
}

.dateInput {
    border-radius: 2px;
    outline: red !important;
    padding-right: 10px !important;
}

.dateInput:focus {
    border: rgb(239 68 68);
    outline: rgb(239 68 68);
}
.dateInput .ant-picker-input>input :hover {
    border: rgb(239 68 68)!important;
    outline: rgb(239 68 68)!important;
}


.ant-picker-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px!important;
    height: 18px!important;
}

.dateInput + .ant-picker-dropdown {
    z-index: 1056!important;
}
