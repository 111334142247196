.tag-input__control {
    border-radius: 0!important;
    min-height: 33px!important;
    width: 288px!important;
}

.tag-input__control--is-focused {
    box-shadow: none!important;
}

.tag-input__multi-value {
    margin: 0!important;
}

.tag-input__value-container {
    padding: 0 8px!important;
}

.tag-input__input-container {
    padding: 0!important;
}

.tag-input__indicator {
    padding: 0 8px!important;
}

.tag-input__multi-value {
    margin-right: 5px!important;
}

.tag-input__multi-value__label {
    padding: 1px 1px 1px 6px!important;
}
