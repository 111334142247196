.ant-pagination-item-active {
    border-color: #E36D38 !important;
}

.ant-pagination-item-active>a {
    color: #E36D38 !important;
}

.blue-style>.ant-pagination-item-active {
    border-color: #19b2e3 !important;
}

.blue-style>.ant-pagination-item-active>a {
    color: #19b2e3 !important;
}